import { EventBus, CART_UPDATED } from '../utils/event-bus'

export default {
  methods: {
    displayError(msg) {
        const errorBox = document.querySelector('.messages .error-msg')
        if (errorBox) {
            errorBox.classList.add('show')
            errorBox.innerHTML = msg
            errorBox.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
    },

    resetError() {
        const errorBox = document.querySelector('.messages .error-msg')
        if (errorBox) {
            errorBox.classList.remove('show')
            errorBox.innerHTML = ''
        }
    },

    saveData(cartData) {
      if (typeof cartData !== 'undefined') {
          localStorage.setItem('cart', JSON.stringify(cartData))
          EventBus.$emit(CART_UPDATED, cartData)
      }
    }
  }
}
