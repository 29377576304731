var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data && Object.keys(_vm.data).length
    ? _c("div", { staticClass: "cart-product__container" }, [
        _vm.data.errors && _vm.data.errors.length
          ? _c(
              "div",
              { staticClass: "cart-product__error h3" },
              _vm._l(_vm.data.errors, function(error, index) {
                return _c("span", { key: index }, [
                  _vm._v("\n            " + _vm._s(error) + "\n        ")
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "cart-product__item" }, [
          _vm.data.imgUrl
            ? _c("div", { staticClass: "cart-product__img" }, [
                _vm.data.rx
                  ? _c("img", {
                      attrs: {
                        src: _vm.getSkinUrl(
                          "images/catalog/product/recipe/recepta1.png"
                        ),
                        width: "205",
                        height: "225",
                        alt: _vm.data.productName ? _vm.data.productName : ""
                      }
                    })
                  : _c("img", {
                      attrs: {
                        src: _vm.data.imgUrl,
                        alt: _vm.data.productName ? _vm.data.productName : ""
                      }
                    })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "cart-product__summary" }, [
            _vm.data.productName
              ? _c("h3", { staticClass: "cart-product__name" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.data.productName) +
                      "\n            "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.data.price
              ? _c("div", { staticClass: "cart-product__total" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.data.qty + " szt.") +
                      "\n                "
                  ),
                  _c("span", { staticClass: "x" }, [_vm._v("x")]),
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.data.price + " zł") +
                      "\n            "
                  )
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _vm.data.rx
          ? _c("div", { staticClass: "cart-product__warning" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1)
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "badge-icon badge-icon--md badge-icon--transparent badge-icon--rotate pull-left"
      },
      [
        _c("span", {
          staticStyle: { width: "50px", height: "50px" },
          attrs: { "data-icon": "info" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "cart-product__warning-text" }, [
      _vm._v(
        "\n            Aby odebrać produkt w aptece, należy okazać receptę lub\n            e-receptę. "
      ),
      _c("br"),
      _vm._v("\n            Możesz również podać farmaceucie\n            "),
      _c("strong", [
        _vm._v(
          "\n                4-cyfrowy kod dostępu widoczny po lewej stronie u góry\n                recepty\n            "
        )
      ]),
      _vm._v(
        "\n            — wtedy pamiętaj, żeby podać także swój numer PESEL.\n        "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }