var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add-to-cart-wrapper" },
    [
      _c("Modal", {
        ref: "modal",
        on: { afterLeave: _vm.destroyCrossSellSlider },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function() {
              return [_c("CartProduct", { attrs: { data: _vm.productData } })]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _vm.productData.cross_sell
                  ? _c("CrossSell", {
                      ref: "crosssell",
                      attrs: { items: _vm.productData.cross_sell }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }