<template>
    <div
        v-if="data && Object.keys(data).length"
        class="cart-product__container"
    >
        <div
            v-if="data.errors && data.errors.length"
            class="cart-product__error h3"
        >
            <span v-for="(error, index) in data.errors" :key="index">
                {{ error }}
            </span>
        </div>
        <div class="cart-product__item">
            <div v-if="data.imgUrl" class="cart-product__img">
                <img
                    v-if="data.rx"
                    :src="
                        getSkinUrl('images/catalog/product/recipe/recepta1.png')
                    "
                    width="205"
                    height="225"
                    :alt="data.productName ? data.productName : ''"
                />
                <img
                    v-else
                    :src="data.imgUrl"
                    :alt="data.productName ? data.productName : ''"
                />
            </div>
            <div class="cart-product__summary">
                <h3 v-if="data.productName" class="cart-product__name">
                    {{ data.productName }}
                </h3>
                <div v-if="data.price" class="cart-product__total">
                    {{ `${data.qty} szt.` }}
                    <span class="x">x</span>
                    {{ `${data.price} zł` }}
                </div>
            </div>
        </div>

        <div v-if="data.rx" class="cart-product__warning">
            <div
                class="badge-icon badge-icon--md badge-icon--transparent badge-icon--rotate pull-left"
            >
                <span data-icon="info" style="width: 50px; height: 50px" />
            </div>

            <p class="cart-product__warning-text">
                Aby odebrać produkt w aptece, należy okazać receptę lub
                e-receptę. <br />
                Możesz również podać farmaceucie
                <strong>
                    4-cyfrowy kod dostępu widoczny po lewej stronie u góry
                    recepty
                </strong>
                — wtedy pamiętaj, żeby podać także swój numer PESEL.
            </p>
        </div>
    </div>
</template>

<script>
import icons from '@js/app/icons.js'

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    mounted() {
        if (this.$el.length !== 0) {
            icons(this.$el)
        }
    }
}
</script>

<style lang="scss">
.cart-product {
    &__container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 480px;
        margin: 0 auto;
    }

    &__img {
        width: 120px;
        margin-right: 30px;
        flex-shrink: 0;

        @media #{$media-xs} {
            width: 80px;
            margin-right: 15px;
        }
    }

    &__summary {
        width: calc(100% - 120px - 30px);

        @media #{$media-xs} {
            width: calc(100% - 120px - 15px);
        }
    }

    &__name {
        font-size: 14px;
        line-height: 24px;
        color: #000;

        @media #{$media-sm-up} {
            font-size: 17px;
        }

        @media #{$media-xs} {
            text-align: center;
        }
    }

    &__total {
        font-size: 27px;
        color: $blue-dianne;

        @media #{$media-xs} {
            margin-top: 15px;
            font-size: 24px;
            clear: both;
            text-align: center;
        }

        .x {
            display: inline-block;
            margin: 0 10px;
        }
    }

    &__warning,
    &__error {
        width: 100%;
        background-color: #e9ecf4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 45px;
        padding-right: 25px;

        @include n-theme() {
            border-bottom: 2px solid theme(color-primary);
        }

        .badge-icon {
            margin-right: -10px;
            margin-bottom: -10px;
            filter: invert(48%) sepia(79%) saturate(176%) hue-rotate(149deg)
                brightness(65%) contrast(119%);

            img {
                transform: scale(0.9);
            }
        }

        p {
            margin: 0;
            text-align: center;
            @include n-theme() {
                color: theme(color-primary);
            }
        }
    }

    &__warning-text {
        padding: 10px 0 10px 10px;
    }

    &__error {
        margin-top: 0;
        background-color: transparent;
        padding: 10px 0;
        @include n-theme() {
            border-bottom: 0;
        }
    }
}
</style>
