<template>
    <div class="add-to-cart-wrapper">
        <Modal ref="modal" @afterLeave="destroyCrossSellSlider">
            <template #content>
                <CartProduct :data="productData" />
            </template>
            <template #footer>
                <CrossSell
                    v-if="productData.cross_sell"
                    ref="crosssell"
                    :items="productData.cross_sell"
                />
            </template>
        </Modal>
    </div>
</template>

<script>
import axios from 'axios'
import Modal from '../partials/Modal.vue'
import CartProduct from './CartProduct.vue'
import CrossSell from './CrossSell.vue'
import { EventBus, PHARMACY_UPDATED } from '../utils/event-bus'
import cartUtils from './mixins'

export default {
    scopeName: 'cart',
    components: {
        Modal,
        CartProduct,
        CrossSell
    },
    mixins: [cartUtils],
    data() {
        return {
            modalVisible: false,
            productData: {}
        }
    },
    mounted() {
        EventBus.$on(PHARMACY_UPDATED, () => {
            if (window[LIBRARY_NAME].pharmacy.addedToCart) {
                const { action, requestObject, button } = window[
                    LIBRARY_NAME
                ].pharmacy.addedToCart
                this.Submit(action, requestObject, button)
            }
        })
    },
    methods: {
        AddManyToCart(trigger, containerId, opts = { onlySelected: false }) {
            opts.event && opts.event.stopPropagation()
            const container = document.getElementById(containerId)

            let forms = Array.from(container.querySelectorAll('form'))
            let trows = Array.from(container.querySelectorAll('tr'))

            if (opts.withoutUnavailable) {
                trows = Array.from(
                    container.querySelectorAll('tr:not(.unavailable)')
                )
            }
            if (opts.onlySelected) {
                const checkedInputs = Array.from(
                    container.querySelectorAll(
                        'input[data-product-checkbox]:checked'
                    )
                )
                forms = checkedInputs.map((el) => el.form)
            } else {
                forms = trows.length
                    ? trows.map((tr) => tr.querySelector('form'))
                    : forms
            }
            forms = forms.filter((el) => el !== null)

            this.AddToCart(trigger, { forms, redirect: true, ...opts })
        },
        AddToCart(trigger, opts = {}) {
            let form = {},
                button

            if (opts.event) {
                opts.event.preventDefault()
                form = opts.event.target
            } else if (trigger.tagName === 'BUTTON') {
                form = trigger.form
                button = trigger
            }

            const forms = opts.forms ? opts.forms : [form]

            const products = this.createProductsArray(forms)

            const requestObject = {
                isAjax: 1,
                ...(opts.redirect && { redirect_to_cart: 1 }),
                ...(form['deleteInactiveQuotes'] && {
                    delete_inactive_quotes: 1
                }),
                form_key: form['form_key'].value,
                products
            }

            const listCategory =
                form['listCategory'] != undefined
                    ? form['listCategory'].value
                    : opts.source != undefined
                    ? opts.source
                    : ''

            if (!Cookies.get('userPharmacy')) {
                Cookies.set(
                    'addedToCart',
                    JSON.stringify({
                        action: form.action,
                        data: requestObject,
                        button: button,
                        list: listCategory
                    })
                )
                window[LIBRARY_NAME].pharmacy.SelectPharmacy()
                return false
            }
            if (products.length) {
                this.Submit(
                    form,
                    requestObject,
                    button,
                    opts.redirect,
                    listCategory,
                    opts.modalOnError
                )
            } else {
                this.displayError('Żaden produkt nie został wybrany.')
            }
        },

        Submit(
            form,
            data,
            button,
            redirect = false,
            listCategory = '',
            modalOnError = false
        ) {
            let submitButton =
                button ||
                (form instanceof Element &&
                    form.querySelector('button[type="submit"]'))
            if (submitButton) {
                submitButton.disabled = true
            }

            let ajaxUrl = ''

            if (data.isAjax) {
                let urlArray = form.action.split('?')
                ajaxUrl =
                    urlArray[0].replace('checkout/cart', 'ajaxcart/index') +
                    '/isAjax/1' +
                    (urlArray[2] === undefined ? '' : urlArray[2])
            }

            axios({
                method: 'post',
                url: ajaxUrl,
                data
            })
                .then((response) => {
                    const data = response.data

                    if (modalOnError && data.errors != undefined) {
                        redirect = false
                    }

                    if (!redirect) {
                        this.saveData(data.cart)
                        this.showModal(data)
                    }
                    if (submitButton && !redirect) {
                        submitButton.disabled = false
                    }
                    delete window[LIBRARY_NAME].pharmacy.addedToCart

                    if (
                        window?.GoogleAnalyticsUniversal &&
                        data.products_added &&
                        data.products_added.length
                    ) {
                        data.products_added.forEach((product) => {
                            window.GoogleAnalyticsUniversal.prototype.addToCart(
                                product.sku,
                                product.productName,
                                product.price,
                                product.qty,
                                product.category,
                                product.categoryId,
                                product.brand,
                                product.variant,
                                listCategory
                            )
                        })
                    }
                    if (redirect || data.redirect) {
                        window.location.href = data.redirect || form.action
                    }
                })
                .catch(() => {
                    submitButton.disabled = false
                    this.$refs.modal.OpenModal({
                        content: ``,
                        buttons: [],
                        footer: '',
                        title:
                            'Przepraszamy, wystąpił błąd serwera. Spróbuj ponownie później.'
                    })
                })
        },

        createProductsArray(forms) {
            return Array.from(forms)
                .filter((form) => form['product'])
                .map((form) => {
                    let { product, qty, category } = form
                    qty = qty && qty instanceof NodeList ? qty[0] : qty
                    return {
                        product: product.value,
                        qty: qty?.value || 1,
                        ...(form['rx-select-level'] && {
                            'rx-select-level': form['rx-select-level'].value
                        }),
                        ...(category && { category: category.value })
                    }
                })
        },

        displayError(msg) {
            const errorBox = document.querySelector('.messages .error-msg')
            if (errorBox) {
                errorBox.classList.add('show')
                errorBox.innerHTML = msg
                errorBox.scrollIntoView({ behavior: 'smooth', block: 'end' })
            }
        },

        showModal(data) {
            if (data.status === 'success') {
                this.productData = data.products_added[0]
                this.$refs.modal.OpenModal({
                    title: data.message,
                    buttons: [
                        {
                            title: 'Złóż zamówienie',
                            primary: false,
                            action: () => {
                                const salesPage =
                                    window.location.href.indexOf(
                                        'checkout/sale'
                                    ) > -1
                                if (salesPage) {
                                    window.location.href = `${BASE_URL}checkout/onepage`
                                } else {
                                    window.location.href = `${BASE_URL}checkout/cart`
                                }
                            }
                        },
                        {
                            title: 'Kontynuuj zakupy',
                            primary: true,
                            action: () => {
                                const cartPage =
                                    window.location.href.indexOf(
                                        'checkout/cart'
                                    ) > -1
                                if (cartPage) {
                                    window.location.href = `${BASE_URL}checkout/cart`
                                }
                            }
                        }
                    ]
                })

                this.crossSellAnalytics(data.products_added[0])
            } else {
                this.productData = {}
                window.GoogleAnalyticsUniversal.prototype.errorSend({
                    category: 1,
                    action: data.errors[0]
                })
                this.$refs.modal.OpenModal({
                    content: ``,
                    buttons: [],
                    footer: '',
                    title: data.errors[0]
                })
            }
        },

        crossSellAnalytics(data) {
            if (data?.cross_sell?.length) {
                if (window?.GoogleAnalyticsUniversal) {
                    let impressions = []
                    data.cross_sell.forEach((item) => {
                        impressions.push(item.gtm)
                        window.staticImpressions[
                            'crosssell-popup-' + item.gtm.id
                        ] = item.gtm
                    })
                    window.GoogleAnalyticsUniversal.prototype.pushImpressions(
                        impressions
                    )
                }
            }
        },

        destroyCrossSellSlider() {
            this.$refs.crosssell.destroyCrossSellSlider()
        },

        ClearPreviousCartSession(containerId) {
            axios({
                method: 'post',
                url: `${BASE_URL}ajaxcart/index/deleteInactiveQuotes`
            }).then(({ data }) => {
                if (data.success) {
                    document.getElementById(containerId).classList.add('hidden')
                }
            })
        }
    }
}
</script>

<style lang="scss">
$mobile: '#{$media-xs}, #{$screen} and (max-width: #{$screen-sm-max}) and (orientation: landscape)';

.add-to-cart-wrapper {
    @media #{$mobile} {
        .custom-modal-actions {
            flex-direction: column-reverse;

            button + button {
                margin-top: 0;
                margin-bottom: 10px;
            }

            button:first-child {
                border-color: transparent;
            }
        }
    }
}
</style>
